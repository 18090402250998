<template>
  <v-app>
    <v-navigation-drawer
      :clipped="true"
      temporary
      v-model="drawer"
      app
      width="auto"
      style="padding-top: 64px">
      <v-list shaped nav>
        <v-list-item-group v-model="selectedMenuItem" color="primary">
          <!-- nav title -->
          <v-list-item disabled>
            <v-list-item-content>
              <v-list-item-title class="title">
                {{ $vuetify.lang.t('$vuetify.menu.adminTitle') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- nav items -->
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :disabled="!user || !user.admin" :to="{ name: 'Home' }" exact>
              <v-list-item-icon>
                <v-icon>{{ colIcons.home }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.home') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">{{ colIcons.right }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :disabled="!user || !user.admin" :to="{ name: 'APLs' }" exact>
              <v-list-item-icon>
                <v-icon>{{ colIcons.apls }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.apluri') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">{{ colIcons.right }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :disabled="!user || !user.admin" :to="{ name: 'PropertyGroups' }" exact>
              <v-list-item-icon>
                <v-icon>{{ colIcons.propertygroups }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.grupuriProprietatiMarkere') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">{{ colIcons.right }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :disabled="!user || !user.admin" :to="{ name: 'Properties' }" exact>
              <v-list-item-icon>
                <v-icon>{{ colIcons.properties }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.proprietatiMarkere') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">{{ colIcons.right }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :disabled="!user || !user.admin" :to="{ name: 'Categories' }" exact>
              <v-list-item-icon>
                <v-icon>{{ colIcons.categories }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.categoriiMarkere') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">{{ colIcons.right }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :disabled="!user || !user.admin" :to="{ name: 'Statuses' }" exact>
              <v-list-item-icon>
                <v-icon>{{ colIcons.statuses }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.statusuriMarkere') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">{{ colIcons.right }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :disabled="!user || !user.admin" :to="{ name: 'Markers' }" exact>
              <v-list-item-icon>
                <v-icon>{{ colIcons.markers }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.markere') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">{{ colIcons.right }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :disabled="!user || !user.admin" :to="{ name: 'UserTypes' }" exact>
              <v-list-item-icon>
                <v-icon>{{ colIcons.usertypes }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.tipuriUtilizatori') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">{{ colIcons.right }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :disabled="!user || !user.admin" :to="{ name: 'Users' }" exact>
              <v-list-item-icon>
                <v-icon>{{ colIcons.users }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.utilizatori') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">{{ colIcons.right }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :disabled="!user || !user.admin" :to="{ name: 'Dictionary' }" exact>
              <v-list-item-icon>
                <v-icon>{{ colIcons.dictionary }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.dictionar') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">{{ colIcons.right }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" :disabled="!user || !user.admin" :to="{ name: 'Language' }" exact>
              <v-list-item-icon>
                <v-icon>{{ colIcons.languages }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.menu.limbiDictionar') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">{{ colIcons.right }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>

          <v-divider></v-divider>

          <!-- log out item -->
          <v-hover v-slot="{ hover }">
            <v-list-item v-show="user" @click="logout">
              <v-list-item-icon>
                <v-icon>{{ colIcons.logout }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ $vuetify.lang.t('$vuetify.app.logoutTitle') }}
                </v-list-item-title>
                <v-list-item-subtitle class="subtitle">
                  <v-icon x-small>
                    {{ user && user.admin ? colIcons.admin : colIcons.user }}
                  </v-icon>
                  {{ user && user.username }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="primary" v-show="hover">{{ colIcons.right }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-hover>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      :clipped-left="true"
      style="flex-grow: 0; z-index: 8"
      >
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="user">
        <slot>
          <v-icon :small="$vuetify.breakpoint.xsOnly">{{ colIcons.menu }}</v-icon>
        </slot>
      </v-app-bar-nav-icon>

      <template v-if="apl && apl.website && apl.website.show">
        <a :href="apl.website.url" target="_blank">
          <v-avatar class="mr-4" :height="$vuetify.breakpoint.xsOnly ? 36 : 48" width="auto" tile>
            <img :src="getURL()">
          </v-avatar>
        </a>
      </template>

      <v-spacer></v-spacer>
      <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.app.title') }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <rhb-i18n />

      <v-btn :small="$vuetify.breakpoint.xsOnly" icon @click="toggleTheme" :title="$vuetify.lang.t('$vuetify.app.theme')">
        <v-icon :small="$vuetify.breakpoint.xsOnly">{{ theme === 'light' ? colIcons.light : colIcons.dark }}</v-icon>
      </v-btn>
      <v-btn :small="$vuetify.breakpoint.xsOnly" icon @click="showLogSheet" v-if="user" :title="$vuetify.lang.t('$vuetify.app.logTitle')">
        <v-icon :small="$vuetify.breakpoint.xsOnly">{{ colIcons.log }}</v-icon>
      </v-btn>
      <v-btn :small="$vuetify.breakpoint.xsOnly" icon @click="showLoginForm" v-if="!user" :title="$vuetify.lang.t('$vuetify.app.authTitle')">
        <v-icon :small="$vuetify.breakpoint.xsOnly">{{ colIcons.login }}</v-icon>
      </v-btn>
      <v-btn :small="$vuetify.breakpoint.xsOnly" icon @click="logout" v-else :title="$vuetify.lang.t('$vuetify.app.logoutTitle')">
        <v-icon :small="$vuetify.breakpoint.xsOnly">{{ colIcons.logout }}</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <!-- login form -->
      <v-dialog
        persistent
        v-model="loginForm"
        width="500">
        <v-card :disabled="loading">
          <v-card-title>
            <span>{{ $vuetify.lang.t('$vuetify.app.loginForm.title') }}</span>
            <v-spacer></v-spacer>
            <v-btn icon small>
              <v-icon small color="error" @click="closeLoginForm">{{ colIcons.close }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form v-model="validForm" ref="form">
              <v-text-field
                @keydown.enter="login"
                v-model="email"
                :label="$vuetify.lang.t('$vuetify.app.loginForm.emailLabel')"
                required
                :rules="[required]">
              </v-text-field>
              <v-text-field
                @keydown.enter="login"
                :type="showPass ? 'text' : 'password'"
                v-model="password"
                :label="$vuetify.lang.t('$vuetify.app.loginForm.passwordLabel')"
                required
                :rules="[required]"
                :append-icon="showPass ? colIcons.visible : colIcons.hidden"
                @click:append="showPass = !showPass">
              </v-text-field>
            </v-form>
            <v-alert v-if="authMsg" type="error">
              <div>
                {{ authMsg }}
              </div>
              <div class="text-caption">
                {{ (new Date()).toLocaleString(localeString) }}
              </div>
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="closeLoginForm">{{ $vuetify.lang.t('$vuetify.app.loginForm.cancelLabel') }}</v-btn>
            <v-btn color="primary" @click="login" :loading="loading">{{ $vuetify.lang.t('$vuetify.app.loginForm.confirmLabel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <router-view />

      <v-bottom-sheet v-model="showLog" scrollable>
        <v-card>
          <v-card-title>
            <span>{{ $vuetify.lang.t('$vuetify.log.title') }}</span>
            <v-spacer></v-spacer>
            <v-text-field
              :prepend-icon="colIcons.search"
              v-model="logSearch"
              :label="$vuetify.lang.t('$vuetify.log.searchTerm')"
              :hint="$vuetify.lang.t('$vuetify.log.searchTermHint')"
              clearable>
            </v-text-field>
            <v-spacer></v-spacer>
            <v-menu
              :open-on-hover="false"
              :close-on-click="true"
              :close-on-content-click="true">
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  color="primary"
                  v-on="on">
                  <v-icon left>{{ colIcons.filter }}</v-icon>
                  {{ $vuetify.lang.t('$vuetify.log.tipMesaje') }} ({{ $vuetify.lang.t(`$vuetify.log.tipMesaje-${logType}`) }})
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="logType in logTypes"
                  :key="logType"
                  @click="setLogType(logType)">
                  <v-list-item-icon>
                    <v-icon :color="logType === 'all' ? 'grey' : logType">{{getIcon(logType)}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $vuetify.lang.t(`$vuetify.log.tipMesaje-${logType}`) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-menu
              :open-on-hover="false"
              :close-on-click="true"
              :close-on-content-click="true">
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  color="primary"
                  v-on="on">
                  <v-icon left>{{ colIcons.page }}</v-icon>
                  {{ $vuetify.lang.t('$vuetify.dataTable.itemsPerPageText') }} ({{logLimit}})
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="limit in [10, 20, 50, 100]"
                  :key="limit"
                  @click="setLogLimit(limit)">
                  <v-list-item-content>
                    <v-list-item-title>{{ limit }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn icon @click="clearLog">
              <v-icon color="error">
                {{ colIcons.clearAll }}
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon small>
              <v-icon color="red" @click="showLog = false">{{ colIcons.close }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="max-height: 75vh">
            <v-list dense>
              <v-list-item
                v-for="msg in logItems"
                :key="msg._id"
                two-line>
                <v-list-item-icon>
                  <v-icon :color="msg.type === 'all' ? 'grey' : msg.type">{{getIcon(msg.type)}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ msg.text }}</v-list-item-title>
                  <v-list-item-subtitle>{{ (new Date(msg.createdAt)).toLocaleString('ro-RO') }} - {{ msg.user }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="deleteLogItem(msg._id)">
                    <v-icon color="error">{{ colIcons.delete }}</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              v-model="logPage"
              :length="logTotalPages"
              :total-visible="7"
              circle>
            </v-pagination>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-bottom-sheet>
    </v-main>
    <a href="https://tudconsult.ro" target="_blank" style="position: absolute; bottom: 50px; left: 20px; padding: 12px">
      <v-avatar class="mr-4" :height="$vuetify.breakpoint.xsOnly ? 36 : 48" width="auto" tile>
        <img src="/logos/logo-tud.png">
      </v-avatar>
    </a>

    <rhb-prompt ref="prompt" />
  </v-app>
</template>

<script>
import _debounce from 'lodash.debounce'
import SERVER_URL from '@/config'
import RhbI18n from '@/components/I18n.vue'

import Prompt from '@/components/Prompt'

import { mandatoryIcons } from '@/utilities'

export default {
  name: 'App',
  components: {
    'rhb-i18n': RhbI18n,
    'rhb-prompt': Prompt
  },
  data: () => ({
    colIcons: Object.freeze(mandatoryIcons),
    SERVER_URL,
    logTypes: ['all', 'success', 'info', 'warning', 'error'],
    validForm: true,
    drawer: false,
    loginForm: false,
    email: null,
    password: null,
    showPass: false,
    showLog: false,
    selectedMenuItem: null,
    authMsg: null,
    loading: false
  }),

  computed: {
    user () {
      return this.$store.getters.user
    },
    logItems () {
      return this.$store.getters.logItems
    },
    logType () {
      return this.$store.getters.logType
    },
    logLimit () {
      return this.$store.getters.logLimit
    },
    logPage: {
      get () {
        return this.$store.getters.logPage
      },
      set (value) {
        this.$store.dispatch('setLogItemsPage', value)
      }
    },
    logTotalPages () {
      return this.$store.getters.logTotalPages
    },
    logSearch: {
      get () {
        return this.$store.getters.logSearch
      },
      set: _debounce(function (value) {
        this.$store.dispatch('setLogSearch', value)
      }, 500)
    },
    apl () {
      return this.$store.getters.currentAPL
    },
    theme () {
      return this.$store.getters.theme
    },
    currentLanguage () {
      return this.$store.getters.currentLanguage
    },
    localeString () {
      return `${this.currentLanguage.languageCode}-${this.currentLanguage.countryCode}`
    }
  },

  methods: {
    toggleTheme () {
      if (this.theme === 'light') {
        this.$store.commit('setTheme', 'dark')
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
        this.$store.commit('setTheme', 'light')
      }
    },
    required (val) {
      return (val !== null && val !== undefined) || this.$vuetify.lang.t('$vuetify.requiredField')
    },
    getIcon (type) {
      switch (type) {
        case 'all': return this.colIcons.logAll
        case 'success': return this.colIcons.logSuccess
        case 'info': return this.colIcons.logInfo
        case 'warning': return this.colIcons.logWarning
        case 'error': return this.colIcons.logError
      }
    },
    setLogLimit (limit) {
      this.$store.dispatch('setLogItemsLimit', limit)
    },
    setLogType (type) {
      this.$store.dispatch('setLogItemsType', type)
    },
    showLogSheet () {
      this.showLog = true
    },
    showLoginForm () {
      this.loginForm = true
    },
    closeLoginForm () {
      this.authMsg = null
      this.loginForm = false
      this.$refs.form.reset()
    },
    async login () {
      this.$refs.form.validate()
      if (this.validForm) {
        this.authMsg = null
        this.loading = true
        const loggedIn = await this.$store.dispatch('login', { email: this.email, password: this.password })
        this.loading = false
        if (loggedIn === true) {
          this.closeLoginForm()

          await this.$refs.prompt.show({
            type: 'success',
            title: this.$vuetify.lang.t('$vuetify.login.longinSuccessTitle'),
            content: this.$vuetify.lang.t('$vuetify.login.longinSuccessContent'),
            okText: this.$vuetify.lang.t('$vuetify.login.longinSuccessOkText'),
            cancelBtn: false
          })
        } else {
          if (loggedIn.name && loggedIn.name === 'TooManyRequests') {
            this.authMsg = this.$vuetify.lang.t('$vuetify.authTooManyRequests')
          } else {
            this.authMsg = this.$vuetify.lang.t('$vuetify.authError')
          }
        }
      }
    },
    async logout () {
      this.$store.dispatch('logout')

      await this.$refs.prompt.show({
        type: 'success',
        title: this.$vuetify.lang.t('$vuetify.logout.logoutSuccessTitle'),
        content: this.$vuetify.lang.t('$vuetify.logout.logoutSuccessContent'),
        okText: this.$vuetify.lang.t('$vuetify.logout.logoutSuccessOkText'),
        cancelBtn: false
      })

      if (this.$route.name !== 'Home') this.$router.push({ name: 'Home' })
    },
    deleteLogItem (id) {
      this.$store.dispatch('deleteLogItem', id)
    },
    clearLog () {
      this.$store.dispatch('deleteLogItem', null)
    },

    async getAPL () {
      const apl = await this.$store.dispatch('getAPL', this.$route.params.id)
      this.apl = Object.freeze(apl)
    },
    getURL () {
      return this.SERVER_URL + this.apl.logo.url
    },

    dummy () {}
  },

  mounted () {
    if (this.theme === 'dark') {
      this.$vuetify.theme.dark = true
    } else {
      this.$vuetify.theme.dark = false
    }
  },

  beforeMount () {
    this.$store.dispatch('reAuth')
    this.$store.dispatch('fetchLanguage')
  }
}
</script>

<style lang="stylus">
  .v-application--wrap {
    min-height: 100dvh;
  }

  /* the last table cell that has a class of .rhb-actions should be displayed in reverse order */
  td:last-child .rhb-actions {
    flex-direction: row-reverse;
  }

  .line-clamp-3 {
    white-space: normal;
    max-width: 25em;
    max-height: 5em;
    overflow:hidden;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:3;
  }

  .gm-style-iw.gm-style-iw-c .gm-style-iw-d {
    overscroll-behavior: contain;
  }

  .v-pagination__item {
    outline: none !important;
  }

  .rhb-fab {
    bottom: 0;
    right: 0 !important;
    position: fixed !important;
    margin: 0 16px 16px 0;
    z-index: 1;
  }

  th[role="columnheader"] {
    white-space: nowrap;
  }

  .table-cell-wrap {
    white-space: normal;
  }
  .table-cell-no-wrap {
    white-space: nowrap;
  }

  .rhb-dict-term:not(:last-child) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    margin-bottom: .25em;
  }

  .rhb-quarter-height {
    max-height: calc( (100vh - 260px - 64px - 36px) / 4 );
    max-height: calc( (100dvh - 260px - 64px - 36px) / 4 );
    overflow-y: auto
  }

  .rhb-unselected {
    .v-avatar, .v-input {
      filter: grayscale(1);
      opacity: 0.75;
    }

    .v-list-item__title, .v-list-item__subtitle {
      opacity: 0.38;
    }
  }

  .rhb-removed {
    position: relative;
    opacity: .95;

    .v-image {
      filter: grayscale(.5);
    }

    &:after {
      position: absolute;
      content: "\d7";
      font-size: 2em;
      background-color: var(--v-error-base);
      color: #ffffff;
      font-weight :bold;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.75;
    }
  }

  .overflow-y-auto {
    overscroll-behavior: contain;
  }

  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .theme--dark {
    .gm-style-iw {
      background-color: #1e1e1e !important;
    }

    .gm-ui-hover-effect > span {
      background-color: #fff;
    }

  }
</style>
